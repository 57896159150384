import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import emailjs from "@emailjs/browser";
import Button from "react-bootstrap/Button";


function Contact(props) {
  let navigate = useNavigate();
  const [name, setName] = useState("")
  // const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("");
  const [messageState, setMessageState] = useState("");
  const [messageSucess, setMessageSucess] = useState("");

  const form = useRef();


  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_d7gw70c", "template_gka1ccm", form.current, {
        publicKey: "i4EbrQr421kSgLhCp",
      })
      .then(
        () => {
          // console.log("SUCCESS!");
          setMessageState(
            "Votre message a était envoyé!"
          );
          setMessageSucess("success");
          setTimeout(() => {
            props.navback("home")
            setMessageState("");
            setMessageSucess("");

          }, 1000);
        },
        (error) => {
          // console.log("FAILED...", error.text);
          setMessageState(
            "Votre message n'a pas était envoyé, Veuillez réesssayer!"
          );
          setMessageSucess("error");
          setTimeout(() => {
            setMessageState("");
            setMessageSucess("");
          }, 1000);
        }
      );
  }


  useEffect(() => {
    props.location("contact");
    if (props.goto) {
       if (props.goto === "service") {
         navigate(`/`, { replace: true });
       } else {
         navigate(`/${props.goto}`, { replace: true });
       }
    }
  }, [props]);

  return (
    <Container className="home_container text_intro">
      <Row className="">
        <Col lg={12} md={12} sm={12} className="">
          <div className="text_top width">
            Laisser nous vos informations et un message, nous vous contacterons
            dans les plus brefs délais!
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12} className="">
          <Form
            ref={form}
            onSubmit={handleSubmit}
            className="message_fields width"
          >
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Nom et Prenom</Form.Label>
              <Form.Control
                name="user_name"
                type="text"
                placeholder="John Doe"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Address email</Form.Label>
              <Form.Control
                required
                name="user_email"
                type="email"
                placeholder="name@example.com"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Message</Form.Label>
              <Form.Control required name="message" as="textarea" rows={3} />
            </Form.Group>
            <Button
              className="button_submit"
              as="input"
              type="submit"
              value="Envoyer"
            />
            <div className="btn_mess">
              <div className="icon"></div>
              <div
                className={
                  messageSucess
                    ? "message_state message_success"
                    : "message_error message_state"
                }
              >
                {messageState}
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Contact;
