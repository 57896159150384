import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams, useNavigate } from "react-router-dom";


function About(props) {
    let navigate = useNavigate();

  useEffect(() => {
    props.location("about");
    if (props.goto) {
      if (props.goto === "service") {
        navigate(`/`, { replace: true });
      }else{
        navigate(`/${props.goto}`, { replace: true });
      }
    }
  }, [props]);

  return (
    <Container className="home_container text_intro">
      <Row className="about_container">
        <Col lg={12} md={12} sm={12} className="col_about">
          <div className="text_container1">
            Burundi Cleaning Company (BCC), opérant depuis 2006, est située au
            76, Blvd Melchior NDADAYE, dans l'immeuble Peace Corner. Fondée par
            BCC en tant que société à responsabilité limitée, elle s'est imposée
            comme un leader dans le secteur du nettoyage industriel, de la
            désinfection, et de l'entretien des espaces verts au Burundi.
            <br></br>
            <br></br>
            Certifiée ISO 9001, notre entreprise témoigne d'un engagement sans
            faille envers la qualité, l'amélioration continue, et la
            satisfaction des clients. Nous adoptons une approche conforme aux
            normes internationales, garantissant une excellence opérationnelle
            dans l'ensemble de nos services.
            <br></br>
            <br></br>
            Au-delà de Bujumbura, BCC étend son rayonnement à toutes les
            provinces du Burundi, offrant une couverture nationale complète. Nos
            services sont accessibles à Gitega, Cibitoke, Rutana, Muramvya,
            Gitaramuka, Rugazi, et d'autres localités, reflétant notre capacité
            à répondre aux besoins de nettoyage et d'entretien à travers le
            pays.
            <br></br>
            <br></br>
            Fiers de notre portefeuille clients prestigieux, comprenant des
            institutions des Nations Unies, des banques, des sociétés de
            télécommunication, et des ONG, nous démontrons notre fiabilité et la
            qualité de nos prestations. Notre équipe, soigneusement sélectionnée
            pour ses compétences, est dirigée par notre Administrateur Directeur
            Général et un Directeur Financier, assurant une gestion efficace et
            une exécution de qualité de tous nos projets.
            <br></br>
            <br></br>
            BCC utilise une gamme d'équipements modernes, garantissant une
            prestation de services à la hauteur des attentes de nos clients,
            avec une attention particulière à la qualité et à la sécurité. Nous
            nous engageons également à utiliser exclusivement des produits de
            nettoyage appropriés, sélectionnés avec soin pour leur efficacité et
            leur conformité aux normes environnementales et de sécurité.
            <br></br>
            <br></br>
            Enregistrée à l'Institut National de Sécurité Sociale (INSS) et
            conforme à ses obligations fiscales, BCC est un modèle d'intégrité
            et de professionnalisme. Notre dévouement à la qualité, à la
            satisfaction client, et au respect de l'environnement fait de nous
            un partenaire de choix pour un Burundi plus propre et plus vert.
          </div>
        </Col>
      </Row>
    </Container>
  );

}


export default About;
