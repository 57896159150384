import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import Maintenance from "./pages/maintenance";
import About from "./pages/about";
import Contact from "./pages/contact";
import Navigation from "./components/nav";
import Footer from "./components/footer";
import "bootstrap/dist/css/bootstrap.css";

function App() {
  const [active, setActive] = useState("");
  const [goto, setGoto] = useState("");
  return (
    <div className="main_container">
      <Navigation
        active={active}
        goto={(goto) => {
          setGoto(goto);
        }}
      />
      <HashRouter>
        <Routes>
          {/* <Route path="/" element={<Maintenance />} /> */}
          <Route
            path="/"
            element={
              <Home
                goto={goto}
                location={(location) => {
                  setActive(location);
                }}
              />
            }
          />
          <Route
            path="/contact"
            element={
              <Contact
                goto={goto}
                location={(location) => {
                  setActive(location);
                }}
                navback={(navback) => {
                  setGoto("service");
                }}
              />
            }
          />
          <Route
            path="/about"
            element={
              <About
                goto={goto}
                location={(location) => {
                  setActive(location);
                }}
              />
            }
          />
        </Routes>
      </HashRouter>
      <Footer />
    </div>
  );
}

export default App;
