import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Home(props) {
  let navigate = useNavigate();
  const ref = useRef(null);
  const [sliderNum, setsliderNum] = useState(3)
const handleResize = () => {
  if (ref.current) {
    const width = ref.current.offsetWidth;
    // console.log(width);
    if (width > 720) {
      // console.log("3");
      setsliderNum(3)
    }
    if (width < 720 && width > 540) {
      // console.log("2");
       setsliderNum(2);
    }

    if (width < 540) {
      // console.log("1");
       setsliderNum(1);
    }
  }
};

  useEffect(() => {
    props.location("service");
    if (props.goto === "service") {
      navigate(`/`, { replace: true });
    } else {
      navigate(`/${props.goto}`, { replace: true });
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
    };
  }, [props]);

  return (
    <Container className="home_container">
      <Row className="top_section">
        <div ref={ref} className="swiper_cont">
          <Swiper
            spaceBetween={1}
            slidesPerView={sliderNum}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            className="mySwiper"
            pagination={{ clickable: false }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="image_container_swiper">
                <div
                  className="img_section1"
                  style={{ background: "url(./assets/section1.jpg)" }}
                ></div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image_container_swiper">
                <div
                  className="img_section1"
                  style={{ background: "url(./assets/section2.jpg)" }}
                ></div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image_container_swiper">
                <div
                  className="img_section1"
                  style={{ background: "url(./assets/section3.jpg)" }}
                ></div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image_container_swiper">
                <div
                  className="img_section1"
                  style={{ background: "url(./assets/section4.jpg)" }}
                ></div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image_container_swiper">
                <div
                  className="img_section1"
                  style={{ background: "url(./assets/section5.jpg)" }}
                ></div>
              </div>
            </SwiperSlide>
          </Swiper>
          <Col lg={12} md={12} sm={12} className="text_intro swiper_text">
            Burundi Cleaning Company (BCC) est une société à responsabilité
            limitée qui s'est imposée comme un leader dans le secteur du
            nettoyage industriel, de la désinfection et de l'entretien des
            espaces verts au Burundi.
            <br></br>
            BCC opère non seulement à Bujumbura mais s'étend également à toutes
            les provinces du Burundi, assurant ainsi une couverture nationale
            complète. Nos services sont disponibles à Gitega, Cibitoke, Rutana,
            Muramvya, Gitaramuka, Rugazi, Cankuzo, Gazorwe, Gashoho, Muyinga
            (Centre), Makamba, Kirundo et d'autres localités, reflétant notre
            capacité à répondre aux besoins de nettoyage et d'entretien à
            travers le pays.
            <br></br>
            <br></br>
            Nos Services sont:
          </Col>
        </div>
      </Row>

      <Row className="card_container">
        <Col lg={4} md={4} sm={12} className="col_img">
          <div className="image_container">
            <div
              className="img_section1"
              style={{ background: "url(./assets/cleaning.png)" }}
            ></div>
          </div>
        </Col>
        <Col lg={8} md={8} sm={12}>
          <div className="text_section1_container">
            <div className="title_section">
              Nettoyage Domestique & Industriel
            </div>
            <span className="text_intro">
              Burundi Cleaning Company (BCC) excelle dans la création d'espaces
              impeccables aussi bien pour les environnements industriels que
              domestiques. Nos équipes hautement qualifiées, équipées de
              machines de pointe, garantissent une propreté méticuleuse dans les
              environnements industriels, respectant les normes internationales
              les plus élevées. Simultanément, notre engagement s'étend aux
              foyers, où des professionnels dédiés utilisent des produits de
              nettoyage soigneusement sélectionnés pour un nettoyage domestique
              efficace et respectueux de l'environnement. Certifiés ISO 9001,
              nous accordons une priorité à l'excellence opérationnelle, à la
              satisfaction client et à l'amélioration continue. En tant que
              partenaire dévoué pour un Burundi plus propre et plus vert, BCC
              harmonise son expertise pour élever les normes à la fois dans le
              nettoyage industriel et domestique.
            </span>
          </div>
        </Col>
      </Row>

      <Row className="card_container">
        <Col lg={4} md={4} sm={12} className="col_img">
          <div className="image_container">
            <div
              className="img_section1"
              style={{ background: "url(./assets/gardening.png)" }}
            ></div>
          </div>
        </Col>
        <Col lg={8} md={8} sm={12}>
          <div className="text_section1_container">
            <div className="title_section">Entretien des espaces verts</div>
            <span className="text_intro">
              L'entretien des espaces verts est une spécialité de BCC, où notre
              équipe dédiée déploie des compétences spécifiques pour maintenir
              des environnements verdoyants et esthétiquement plaisants. Munis
              d'outils d'aménagement paysager modernes tels que des tondeuses et
              des souffleurs, nous nous engageons à créer des espaces extérieurs
              attrayants. Nos pratiques respectueuses de l'environnement
              garantissent une gestion durable des espaces verts, ajoutant une
              touche de nature harmonieuse à chaque lieu.
            </span>
          </div>
        </Col>
      </Row>

      <Row className="card_container">
        <Col lg={4} md={4} sm={12} className="col_img">
          <div className="image_container">
            <div
              className="img_section1"
              style={{ background: "url(./assets/exterminator.png)" }}
            ></div>
          </div>
        </Col>
        <Col lg={8} md={8} sm={12}>
          <div className="text_section1_container">
            <div className="title_section">Fumigation</div>
            <span className="text_intro">
              BCC se distingue dans le domaine de la fumigation en offrant des
              services spécialisés pour éliminer les nuisances liées aux
              insectes, parasites et agents pathogènes. Nos techniciens
              qualifiés utilisent des méthodes modernes de dispersion, assurant
              une élimination efficace tout en respectant les normes de sécurité
              strictes. Que ce soit pour des espaces résidentiels, commerciaux
              ou institutionnels, notre approche rigoureuse de la fumigation
              garantit un environnement sain et protégé.
            </span>
          </div>
        </Col>
      </Row>

      <Row className="card_container">
        <Col lg={4} md={4} sm={12} className="col_img">
          <div className="image_container">
            <div
              className="img_section1"
              style={{ background: "url(./assets/disinfection.png)" }}
            ></div>
          </div>
        </Col>
        <Col lg={8} md={8} sm={12}>
          <div className="text_section1_container">
            <div className="title_section">Desinfection</div>
            <span className="text_intro">
              La désinfection est une priorité pour BCC, et notre équipe dévouée
              utilise des méthodes avancées pour éliminer les agents pathogènes
              et assurer des espaces débarrassés de toute menace pour la santé.
              Nous mettons en œuvre des protocoles de désinfection conformes aux
              normes internationales, utilisant des produits certifiés pour
              garantir l'efficacité tout en préservant la sécurité. Que ce soit
              dans des espaces commerciaux, résidentiels ou institutionnels, BCC
              s'engage à créer des environnements désinfectés, favorisant la
              santé et le bien-être.
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
