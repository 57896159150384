import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

function Navigation(props) {
  const [active, setActive] = useState();
  useEffect(() => {
    setActive(props.active);
  }, [props]);
  return (
    <Container fluid className="nav_container">
      <Container className="small_screen">
        <Row>
          <Col className="nav_item" lg={6} md={12} sm={12}>
            <div className="logo_section">
              <div className="logo_container ">
                <div
                  style={{ background: "url(./assets/logo.png)" }}
                  className="logo_img"
                ></div>
              </div>
              <div className="text_container">
                <div className="title_top text_logo">
                  BURUNDI CLEANING COMPANY
                </div>
                <div className="title_btm text_logo">
                  Votre propreté, notre affaire
                </div>
              </div>
            </div>
          </Col>
          <Col className="nav_item" lg={6} md={12} sm={12}>
            <div className="btn_container">
              <div
                className={
                  active === "service" ? "btn_item active" : "btn_item"
                }
                onClick={()=>{props.goto("service");}}
              >
                Nos Services
              </div>
              <div
                onClick={()=>{props.goto("contact");}}
                className={
                  active === "contact"
                    ? "btn_item active mar_left_10"
                    : "btn_item mar_left_10"
                }
              >
                Contactez Nous
              </div>
              <div
                className={
                  active === "about"
                    ? "btn_item active mar_left_10"
                    : "btn_item "
                }
                onClick={()=>{props.goto("about");}}
              >
                A Propos de BCC
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Navigation;
