import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function Footer(props) {
  return (
    <Container className="footer_container low_sticky" fluid>
      <Container>
        <Row className="footer_container">
          <Col className="col_footer" lg={6} md={12} sm={12}>
            <div className="logo_footer_container">
              <div
                style={{ background: "url(./assets/footer.png)" }}
                className="logo_footer_img"
              ></div>
            </div>
          </Col>

          <Col className="col_footer" lg={6} md={12} sm={12}>
            <div className="col_contact">
              <div className="contact_title">Contactez Nous</div>

              <div className="phone_container item_contact_container">
                <div className="icon_container">
                  <svg
                    t="1708170880290"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1469"
                  >
                    <path
                      d="M911.242667 708.565333q0 15.424-5.717333 40.277333t-12.010667 39.146667q-12.010667 28.565333-69.717333 60.565333-53.717333 29.141333-106.282667 29.141333-15.424 0-29.994667-2.005333t-32.853333-7.146667-27.136-8.277333-31.722667-11.712-28.010667-10.282667q-56-20.010667-100.010667-47.424-73.152-45.141333-151.146667-123.136t-123.136-151.146667q-27.434667-44.010667-47.424-100.010667-1.706667-5.141333-10.282667-28.010667t-11.712-31.722667-8.277333-27.136-7.146667-32.853333-2.005333-29.994667q0-52.565333 29.141333-106.282667 32-57.706667 60.565333-69.717333 14.293333-6.293333 39.146667-12.010667t40.277333-5.717333q8 0 12.010667 1.706667 10.282667 3.434667 30.293333 43.434667 6.293333 10.858667 17.152 30.848t20.010667 36.288 17.706667 30.570667q1.706667 2.282667 10.005333 14.293333t12.288 20.288 4.010667 16.277333q0 11.434667-16.277333 28.565333t-35.434667 31.424-35.434667 30.293333-16.277333 26.282667q0 5.141333 2.858667 12.864t4.864 11.712 8 13.717333 6.570667 10.858667q43.434667 78.293333 99.434667 134.293333t134.293333 99.434667q1.152 0.576 10.858667 6.570667t13.717333 8 11.712 4.864 12.864 2.858667q10.282667 0 26.282667-16.277333t30.293333-35.434667 31.424-35.434667 28.565333-16.277333q8 0 16.277333 4.010667t20.288 12.288 14.293333 10.005333q14.293333 8.576 30.570667 17.706667t36.288 20.010667 30.848 17.152q40 20.010667 43.434667 30.293333 1.706667 4.010667 1.706667 12.010667z"
                      fill="#222222"
                      p-id="1470"
                    ></path>
                  </svg>
                </div>
                <div className="phone contact_item">+25761800500</div>
              </div>

              <div className="email_container border_space_2 item_contact_container">
                <div className="icon_container">
                  <svg
                    t="1708170913868"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="1507"
                  >
                    <path
                      d="M935.335233 153.62202h-846.666656a84.666666 84.666666 0 0 0-84.666666 84.666666v550.333327a84.666666 84.666666 0 0 0 84.666666 84.666665h846.666656a84.666666 84.666666 0 0 0 84.666666-84.666665v-550.333327a84.666666 84.666666 0 0 0-84.666666-84.666666z m-27.293711 213.952665L557.558216 549.672927a94.993177 94.993177 0 0 1-87.065555 0.197555l-354.612218-182.202664a42.333333 42.333333 0 0 1 38.698311-75.308177l354.606573 182.202664a10.196689 10.196689 0 0 0 9.341556-0.022577l350.477662-182.089776a42.333333 42.333333 0 1 1 39.034155 75.127555z"
                      fill="#231815"
                      p-id="1508"
                    ></path>
                  </svg>
                </div>
                <div className="email contact_item">societebcc@gmail.com</div>
              </div>

              <div className="date_container border_space_1 item_contact_container">
                <div className="icon_container">
                  <svg
                    t="1708171542132"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="2628"
                  >
                    <path
                      d="M928 224H768v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H548v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H328v-56c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v56H96c-17.7 0-32 14.3-32 32v576c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V256c0-17.7-14.3-32-32-32z m-40 568H136V296h120v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h148v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h148v56c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-56h120v496z"
                      p-id="2629"
                      fill="#000000"
                    ></path>
                    <path
                      d="M416 496H232c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM416 632H232c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM724.2 454.6L620.6 598.3l-52.8-73.1c-3-4.2-7.8-6.6-12.9-6.6H500c-6.5 0-10.3 7.4-6.5 12.7l114.1 158.2c6.4 8.8 19.4 8.8 25.8 0l165-228.7c3.8-5.3 0-12.7-6.5-12.7H737c-5-0.1-9.8 2.4-12.8 6.5z"
                      p-id="2630"
                      fill="#000000"
                    ></path>
                  </svg>
                </div>
                <div className="email contact_item">Du Lundi au Samedi</div>
              </div>

              <div className="time_container item_contact_container">
                <div className="icon_container">
                  <svg
                    t="1708171635821"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="3720"
                  >
                    <path
                      d="M512 0C229.888 0 0 229.888 0 512s229.888 512 512 512 512-229.888 512-512S794.112 0 512 0z m442.368 512c0 243.712-198.144 442.368-442.368 442.368-243.712 0-442.368-198.144-442.368-442.368 0-243.712 198.144-442.368 442.368-442.368 243.712 0 442.368 198.656 442.368 442.368z"
                      fill="#000000"
                      p-id="3721"
                    ></path>
                    <path
                      d="M546.816 498.176V232.96c0-19.456-15.872-34.816-34.816-34.816-19.456 0-34.816 15.872-34.816 34.816v280.064c0 9.216 3.584 17.92 10.24 24.576l130.56 130.048c6.656 6.656 15.36 10.24 24.576 10.24 9.216 0 17.92-3.584 24.576-10.24 6.656-6.656 10.24-15.36 10.24-24.576 0-9.216-3.584-17.92-10.24-24.576l-120.32-120.32z"
                      fill="#000000"
                      p-id="3722"
                    ></path>
                  </svg>
                </div>
                <div className="email contact_item">De 7:00 à 17:00</div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Row>
        <Col className="col_footer_copyright" lg={12} md={12} sm={12}>
          <div className="copyright">
            Copyright© 2024 and Designed by PhilipDev & Co | All
            rights reserved
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
